
import { StyleCheck } from "@/mixins/style-check";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BillInfo extends mixins(StyleCheck) {
  @Prop() staff!: any;
  dialog = false;
}
